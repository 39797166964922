import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";


const FaqSection = () => {
  const [element, controls] = useScroll();
  return (
    <Faq
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        On fait connaissance ? <span>Plus d'informations sur moi</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Pourquoi est-ce que je suis devenue développeuse ?">
          <div className="answer">
          <p>Après cinq and de carrière dans le commerce international, je ne voulais plus vendre, je voulais créer. J'ai eu la chance de travailler pour des entreprises numeriques et innovateurs, alors j'étais attiré par le développement web.</p>
          <p>Alors j'ai commencé une reconversion professionnelle grâce à OpenClassrooms. J'ai suivi ma formation de façon independante, et j'ai créé des projets professionalisants. Ce diplôme m'a bien préparé pour ma nouvelle carrière et m'a permis d'être opérationnelle toute de suite.</p>
          </div>
        </Toggle>
        <Toggle title="Quelles sont mes qualifications ?">
          <div className="answer">
            <p>J'ai un diplôme en développement web - titre enregistré au <a href="https://www.francecompetences.fr/recherche/rncp/32173/">RNCP</a> de niveau 5 (Bac +2).</p>
            <p>J'ai une license (Bac +3) en Français avec une mention très bien en français oral, de l'Université de Leeds.</p>
          </div>
        </Toggle>
        <Toggle title="Comment est-ce qu'on peut travailler ensemble ?">
          <div className="answer">
            <p>Je suis disponible pour des projets freelance, ainsi que des contrats permanents.</p>
            <p>
              Quelque soit votre projet, n'hésitez pas à me contacter - je serais ravie d'en discuter en détail. 
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled(About)`
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default FaqSection;
