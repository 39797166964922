import React from 'react';
import styled from 'styled-components';

//Fontawesome 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithubSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"

const Footer = () => {
    return (
        <StyledFooter>
            <p>Réalisé par Emma Liefmann</p>
            <div>
                <a href="mailto:emmaliefmann@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" className="icon"/> 
                </a>

                <a href="https://www.linkedin.com/in/emmaliefmann/" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" className="icon"/> 
                </a>
                <a href="https://github.com/emmaliefmann" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faGithubSquare} size="2x" className="icon" /> 
                </a>

            </div>
        </StyledFooter>
         
    );
};

const StyledFooter = styled.div`
background-color: #182938;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
p {
    color: white;
    padding-bottom: 20px;
}
div {
    color: white;
    width: 120px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    .icon {
        color: white;
    }
}
`;

export default Footer;