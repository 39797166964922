import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, photoAnim, titleAnim, sliderContainer, slider } from "../animation";
import styled from "styled-components";
//Fontawesome 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithubSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"
//images 
import contact from "../img/contact1.jpg";
const Contact = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>
      <Title>
        <Hide>
          <motion.h2 variants={titleAnim}>Discutons ensemble de votre projet.</motion.h2>
        </Hide>
      </Title>
      <StyledImage>
        <motion.img variants={photoAnim} src={contact}/>
      </StyledImage>
      <Container>
        <Hide>
          <Social variants={titleAnim}>
            <FontAwesomeIcon icon={faEnvelope} size="4x" />
            <a href="mailto:emmaliefmann@gmail.com">Envoyer un email</a>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            
            <FontAwesomeIcon icon={faLinkedin} size="4x" />
            <a href="https://www.linkedin.com/in/emmaliefmann/" rel="noopener noreferrer" target="_blank">Connexion sur Linkedin</a>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <FontAwesomeIcon icon={faGithubSquare} size="4x" />
            <a href="https://github.com/emmaliefmann" rel="noopener noreferrer" target="_blank">Voir mes repos Github</a>
          </Social>
        </Hide>
      </Container>
    </ContactStyle>
  );
};

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #396185;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #3C4752;
`;
const Frame3 = styled(Frame1)`
  background: #182938;
`;
const Frame4 = styled(Frame1)`
  background: #5A99D1;
`;
const ContactStyle = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #353535;
  background: #fff; 
  min-height: 90vh;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;
const Container = styled.div`
  margin-bottom: 100px;
  width: 80%;
`;
const Title = styled.div`
  margin: 4rem 0rem;
  color: black;
  width: 80%;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
const StyledImage = styled.div`
  z-index: 2;
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: 80%;
    object-fit: cover;
  }
`;

const Social = styled(motion.div)`
  display: flex;
  align-items: center;
  a {
    text-decoration: none; 
    font-family: 'Roboto Slab', serif;
    color: black;
    margin: 2rem;
    font-size: 4rem;
  }
  @media (max-width: 768px) {
    a {
      font-size: 2rem;
    }
  }
`;

export default Contact;
