import React from "react";
import { motion } from "framer-motion";

import home2 from "../img/home2.jpg";
//Fontawesome 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhp, faReact, faJs, faVuejs, faSass, faHtml5, faNode, faCss3Alt} from "@fortawesome/free-brands-svg-icons";

//Styles
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";

const LanguagesSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>
          Mes <span>langages</span> de développement
        </h2>
        <Cards>
          <Card >
            <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg" icon={faReact} />
              <p>React</p>
            </motion.div>
            
          </Card>
          <Card>
          <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
              <FontAwesomeIcon size="3x" className="iconSvg"icon={faJs} />
              <p>Javascript</p>
            </motion.div>
          </Card>
          <Card>
          <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg" icon={faNode} />
              <p>Node JS</p>
              </motion.div>
          </Card>
          <Card>
            <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
              <FontAwesomeIcon size="3x"className="iconSvg" icon={faPhp} />
              <p>PHP</p>
              </motion.div>
          </Card>
          
          <Card>
            <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg"icon={faHtml5} />
              <p>HTML</p>
              </motion.div>
          </Card>
          <Card>
            <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg"icon={faCss3Alt} />
              <p>CSS</p>
              </motion.div>
       
          </Card>
          <Card>
          <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg"icon={faVuejs} />
              <p>Vue.js</p>
              </motion.div>
            
          </Card>
          <Card>
          <motion.div className="icon" whileHover={{ scale: 1.1, transition: {duration: 0.5}  }}>
            <FontAwesomeIcon size="3x" className="iconSvg" icon={faSass} />
              <p>Sass</p>
            </motion.div>
       
          </Card>
        </Cards>
      </Description>
      <Image>
        <img alt="camera" src={home2} />
      </Image>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  flex-basis: 20rem;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    p {
      margin-left: 1rem;
      background-image: linear-gradient(to bottom right, #AEB4BF, #E8F0FF);
      color: #182938;
      padding: 0.6rem;
      text-align: center;
      border-radius: 5%;
    }
    .iconSvg {
      color:  #182938;
    }
  }
`;

export default LanguagesSection;
