import React from "react";
import headshot1 from "../img/emmaliefmann1.jpg";
import { About, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>Emma Liefmann <br/></motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
               <span>développeuse web</span> bilingue et
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>passionnée par le web.</motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          Développeuse Nantaise spécialisée dans la création de fonctionnalités frontend.  
        </motion.p>
        <motion.a className="button" variants={fade} href="mailto:mailto:emmaliefmann@gmail.com">Travailler avec moi</motion.a>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={headshot1} alt="femme qui travaille sur ordinateur" />
      </Image>
      <Wave />
    </About>
  );
};


export default AboutSection;
