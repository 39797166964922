import React from "react";
import styled from "styled-components";
//Images
import theracer from "../img/typewriter.jpg";
import goodtimes from "../img/cooking1.jpg";
import bike2 from "../img/bike3.jpg";
import telethon from "../img/telethon.jpg";
//Animations
//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";
//icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhp, faJs, faHtml5, faCss3Alt} from "@fortawesome/free-brands-svg-icons";

const Projects = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();
  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>
      <StyledProject>
        <motion.h2 variants={fade}>Création de village virtuel</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        
          <StyledCard>
            <motion.img variants={photoAnim} src={telethon} alt="bike" />
            <motion.div variants={photoAnim} className="description">
              <h3>AFM Téléthon</h3>
              <p>Dans l'équipe frontend, réalisation du nouveau site e-commerce pour le Téléthon.</p>
              <a class="button" href="https://www.leparisien.fr/hauts-de-seine-92/hauts-de-seine-un-village-virtuel-pour-participer-au-telethon-presque-normalement-04-12-2020-8412313.php">Voir le projet</a>
              <div>
                <FontAwesomeIcon size="3x" icon={faJs} />
                <FontAwesomeIcon size="3x" icon={faHtml5} />
                <FontAwesomeIcon size="3x" icon={faCss3Alt} />
              </div>
            </motion.div>
          </StyledCard>
      </StyledProject>

      <StyledProject ref={element} variants={fade} animate={controls} initial="hidden">
        <h2>Créer une platforme blog en PHP</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <StyledCard>
              <motion.div variants={photoAnim} className="description">
                <h3>Billet Simple pour l'Alaska</h3>
                <p>Création de frontend et backend en PHP avec une architecture MVC. Programmation en oriénté objet.</p>
                <a class="button" href="https://projet4.emmaliefmann.com/">Voir le projet</a>
                <div>
                <FontAwesomeIcon size="3x" icon={faPhp} />
                <FontAwesomeIcon size="3x" icon={faHtml5} />
                <FontAwesomeIcon size="3x" icon={faCss3Alt} />
              </div>
              </motion.div>
              <motion.img variants={photoAnim} src={theracer} alt="theracer"  />
            </StyledCard>
      </StyledProject>

      <StyledProject
        ref={element2}
        variants={fade}
        animate={controls2}
        initial="hidden"
      >
        <h2>Une site communautaire</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        
          <StyledCard>
            <motion.img variants={photoAnim} src={goodtimes} alt="goodtimes"  />
            <motion.div variants={photoAnim} className="description">
              <h3>Sharing Table</h3>
              <p>Conception et développement d'un site communautaire pour partager des recettes.</p>
              <a class="button" href="https://sharingtable.emmaliefmann.com/">Voir le projet</a>
              <div>
                <FontAwesomeIcon size="3x" icon={faPhp} />
                <FontAwesomeIcon size="3x" icon={faHtml5} />
                <FontAwesomeIcon size="3x" icon={faCss3Alt} />
              </div>
            </motion.div>
            </StyledCard>
        
      </StyledProject>
      <StyledProject
        ref={element3}
        variants={fade}
        animate={controls3}
        initial="hidden"
      >
        <h2>Carte Interactive en Javascript</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        
          <StyledCard>
            <motion.div variants={photoAnim} className="description">
              <h3>Location de vélo à Nantes</h3>
              <p>Avec l'API de JC Décaux, une application web pour réserver et louer un vélo.</p>
              <a class="button" href="https://bikerental.emmaliefmann.com/">Voir le projet</a>
              <div>
                <FontAwesomeIcon size="3x" icon={faPhp} />
                <FontAwesomeIcon size="3x" icon={faHtml5} />
                <FontAwesomeIcon size="3x" icon={faCss3Alt} />
              </div>
            </motion.div>
            <motion.img variants={photoAnim} src={bike2} alt="goodtimes"  />
            </StyledCard>
        
      </StyledProject>
      
      <ScrollTop />
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5% 12%;
  display: flex;
  flex-direction: column; 
  align-items: center;
  @media (max-width: 800px) {
    padding: 2rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const StyledProject = styled(motion.div)`
  padding-bottom: 10rem;
 
  .line {
    height: 0.5rem;
    background: #5A99D1;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;
const StyledCard = styled.div`
  overflow: hidden;
  display: flex;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.4);
  max-height: 555px;
  max-width: 995px;
  text-decoration: none;
    img {
      width: 50%;
      
    }
    .description {
      width: 50%;
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-image: linear-gradient(to bottom right, #AEB4BF, #E8F0FF);
      
      div {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 50%;
        color: #182938;
      }
      h3 {
        font-size: 2.5rem;
        color: #182938;
      }
      a {
        text-decoration: none;
      }  
      
    }
  @media (max-width: 600px) {
      flex-direction: column;
      max-height: 500px;
      img {
        width: 100%;
        max-height: 250px; 
      }
      .description {
        width: 100%;
        height: 250px;
        h3 {
          text-align: center;

        }
        p {
          display: none;
        }
      }
  }
  
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #396185;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #3C4752;
`;
const Frame3 = styled(Frame1)`
  background: #182938;
`;
const Frame4 = styled(Frame1)`
  background: #5A99D1;
`;

export default Projects;
